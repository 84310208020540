_cruxUtils._cruxLocale = {
"crux.no.bc.message":"Nincs el\u00e9rhet\u0151 \u00e1ttekint\u00e9s. Tov\u00e1bbi r\u00e9szletek megtekint\u00e9s\u00e9hez kattintson a rekordra",
"crm.appointments.status.markcompleted1": "Megjel\u00f6l\u00e9s befejezettk\u00e9nt",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">Az ebben a n\u00e9zetben szerepl\u0151 \u00f6sszes\u00a0</span><span class='crm-font-bold mL3 mR3 dIB vaM recordCountHt'>{0}</span><span class=\"dIB vaM\"> {1} ki van jel\u00f6lve.</span>",
"crm.module.selectall": "Ez ebben a n\u00e9zetben szerepl\u0151 \u00f6sszes {0} kijel\u00f6l\u00e9se",
"records": "rekord",
"crm.record.not.accessible": "A bejegyz\u00e9s(ek) nem \u00e9rhet\u0151(k) el",
"crm.view.calls.recording.err.msg": "A hangfelv\u00e9tel nem rendelkezik lej\u00e1tszhat\u00f3 hang URL-c\u00edmmel.",
"crm.listview.selected.records": "<span class='crm-font-bold mL3 crm-small-font-size vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} kiv\u00e1lasztva.</span>",
"totalrecords": "\u00d6sszes megjegyz\u00e9s",
"crm.listview.options.text.clip": "Igaz\u00edt\u00e1s a sz\u00e9less\u00e9ghez",
"crm.listview.options.text.wrap": "Sort\u00f6r\u00e9ssel t\u00f6bb sorba",
"crm.listview.options.reset.width": "Oszlopm\u00e9ret vissza\u00e1ll\u00edt\u00e1sa",
"crm.listview.options.manage": "Oszlopok kezel\u00e9se",
"crm.listview.customview.locked": "Az oszlopok testreszab\u00e1sa nem lehets\u00e9ges, mert ez a n\u00e9zet z\u00e1rolva van",
"crm.api.apidashboard.SortBy": "Rendez\u00e9s szempontja:",
"crm.privacy.listview.consent.locked": "A bejegyz\u00e9s feldolgoz\u00e1sa z\u00e1rolva van",
"crm.label.reschedule.call": "{0} \u00fajra\u00fctemez\u00e9se",
"crm.button.edit": "Szerkeszt\u00e9s",
"Delete": "T\u00f6rl\u00e9s",
"crm.appointments.status.markcancel": "{0} t\u00f6rl\u00e9se",
"crm.appointments.status.markreschedule": "{0} \u00fajra\u00fctemez\u00e9se",
"crm.label.cancel.call": "{0} t\u00f6rl\u00e9se",
"crm.label.mark.completed": "Megjel\u00f6l\u00e9s befejezettk\u00e9nt",
"crm.listview.customview.locked": "Az oszlopok testreszab\u00e1sa nem lehets\u00e9ges, mert ez a n\u00e9zet z\u00e1rolva van",
"pf.deleted.state": "T\u00f6r\u00f6lt \u00e1llapot",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "Szinkroniz\u00e1l\u00e1s a k\u00f6vetkez\u0151vel: {0}",
"crm.schedules.prefix.in": "Ennyi id\u0151 m\u00falva:",
"crm.record.record.locked.other": "A bejegyz\u00e9s m\u00e1sik profilfelhaszn\u00e1l\u00f3 sz\u00e1m\u00e1ra z\u00e1rolva van.",
"crm.record.lock.record.locked": "A bejegyz\u00e9s z\u00e1rolva van.",
"crm.approvalProcess.label.waitingForApproval": "V\u00e1rakoz\u00e1s a j\u00f3v\u00e1hagy\u00e1sra",
"crm.reviewprocess.record.review.pending": "V\u00e9lem\u00e9nyez\u00e9sre v\u00e1r",
"crm.approvalProcess.label.waitingForFindAndMerge": "Az ism\u00e9tl\u0151d\u0151 elemek egyes\u00edt\u00e9se folyamatban van.",
"crm.privacy.listview.consent.locked": "A bejegyz\u00e9s feldolgoz\u00e1sa z\u00e1rolva van",
"crm.zia.vision.processing": "A Zia \u00e9rv\u00e9nyes\u00edti a k\u00e9pe(ke)t.",
"crm.zia.vision.rejected.msg": "A bejegyz\u00e9st a rendszer k\u00e9p\u00e9rv\u00e9nyes\u00edt\u00e9si hiba miatt elutas\u00edtotta.",
"crm.zia.vision.record.failure.msg": "A bejegyz\u00e9s k\u00e9p\u00e9rv\u00e9nyes\u00edt\u00e9si hiba miatt j\u00f3v\u00e1hagy\u00e1sra v\u00e1r.",
"crm.alert.max.cvcols.reached": "Legfeljebb {0} oszlopot jel\u00f6lhet ki.",
"crm.label.small.tags": "C\u00edmk\u00e9k",
"crm.label.edit.module": "{0} szerkeszt\u00e9se",
"crm.social.integ.no.result": "Nincs tal\u00e1lat",
"custommodule.crmfield.fl.name": "{0} neve",
"Appointment": "Tal\u00e1lkoz\u00f3",
"crm.button.create.call": "H\u00edv\u00e1s l\u00e9trehoz\u00e1sa",
"crm.module.create": "L\u00e9trehoz {0}",
"crm.customview.activty.badge": "M\u0171veletjelv\u00e9ny",
"crm.button.callnow": "H\u00edv\u00e1s",
"crm.manualcalllist.complete.call": "H\u00edv\u00e1s napl\u00f3z\u00e1sa",
"crm.label.schedule.call": "H\u00edv\u00e1s \u00fctemez\u00e9se",
"crm.filter.label.replied":"megv\u00e1laszolt",
"bytes": "byte",
"crm.field.label.user.lookup": "Felhaszn\u00e1l\u00f3",
"crm.translation.refresh.cases": "A v\u00e9grehajtani k\u00edv\u00e1nt m\u0171veletet nem lehet befejezni. Friss\u00edtse az oldalt, majd pr\u00f3b\u00e1lja \u00fajra.",
"crm.project.sync.notes.info": "Csak a t\u00e1rs\u00edtott projektek feladataihoz f\u0171z\u00f6tt megjegyz\u00e9sek fognak megjelenni",
"crux.note.content.limit.warning.msg":"A jegyzetek tartalm\u00e1ban csak {0} karakter ment\u00e9se megengedett",
"crux.note.formatting.options":"Form\u00e1z\u00e1si be\u00e1ll\u00edt\u00e1sok",
"crm.label.required": "K\u00f6telez\u0151",
"crux.condition.not.belongs.to":"nem tartozik a k\u00f6vetkez\u0151h\u00f6z: {0}",
"crm.project.sync.notes.info": "Csak a t\u00e1rs\u00edtott projektek feladataihoz f\u0171z\u00f6tt megjegyz\u00e9sek fognak megjelenni",
"crm.sf.permission.no.edit": "Nincs enged\u00e9lye a bejegyz\u00e9sek friss\u00edt\u00e9s\u00e9hez.",
"crm.sf.permission.no.create": "Nincs enged\u00e9lye bejegyz\u00e9sek hozz\u00e1ad\u00e1s\u00e1hoz.",
"crm.sf.permission.no.delete": "Nincs enged\u00e9lye bejegyz\u00e9sek t\u00f6rl\u00e9s\u00e9hez.",
"crux.note.this.note":"ez a jegyzet",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "Bejelentkezett felhaszn\u00e1l\u00f3 szerepk\u00f6re",
"Unit Price": "Egys\u00e9g\u00e1r",
"Qty in Stock": "K\u00e9szleten l\u00e9v\u0151 mennyis\u00e9g",
"crm.label.Product Code": "Term\u00e9kk\u00f3d",
"TotalAfterDiscount": "V\u00e9g\u00f6sszeg a kedvezm\u00e9ny levon\u00e1sa ut\u00e1n",
"crm.lookupfilter.entity.errormsg": "A kijel\u00f6lt {0} nem felel meg a keres\u00e9si krit\u00e9riumoknak.",
"crm.iamexception.maxlen": "Nem \u00edrhat be {0} f\u00f6l\u00f6tti sz\u00e1m\u00fa karaktert a k\u00f6vetkez\u0151h\u00f6z: {1}",
"crm.record.locking.permission.denied": "A m\u0171velet nem hajthat\u00f3 v\u00e9gre, mert a bejegyz\u00e9s z\u00e1rolva van.",
"List Price": "Lista\u00e1r",
"crm.label.no.more.records": "Nincs t\u00f6bb bejegyz\u00e9s",
"crm.workflow.rule.created.dateAndTime":"{0} \u2013 {1} napon, {2} id\u0151pontban",
"crux.new.note.msg":"\u201eMire vonatkozik ez a jegyzet?",
"crm.image.uploaded":"K\u00e9p felt\u00f6ltve",
"crm.imageupload.drag.drop.here":"H\u00fazza ide a k\u00e9pet",
"crm.fileupload.drag.drop.here":"H\u00fazza ide a f\u00e1jlokat",
"crm.fileupload.wait.msg":"V\u00e1rja meg a kiv\u00e1lasztott f\u00e1jlok felt\u00f6lt\u00e9s\u00e9t",
"crm.fileupload.attach.fail":"A csatol\u00e1s nem siker\u00fclt",
"crm.fileupload.support.format":"Zia betan\u00edt\u00e1s\u00e1hoz t\u00e1mogatott f\u00e1jlform\u00e1tumok (jpeg, jpg, png, pdf)",
"custmr.prtl.user.role": "Port\u00e1lfelhaszn\u00e1l\u00f3",
"crm.subform.empty.alert.header":"\u00dcres sort nem lehet menteni",
"crm.crud.lookup.module.inaccessible": "Nincs enged\u00e9lye a keres\u00e9s modul el\u00e9r\u00e9s\u00e9hez",
"crm.crud.lookup.inaccessible.record": "A bejegyz\u00e9s nem l\u00e9tezik, vagy nem el\u00e9rhet\u0151 az \u00d6n sz\u00e1m\u00e1ra",
"crm.custombutton.valid.weburl.check": "K\u00e9rj\u00fck, adjon meg valamilyen \u00e9rt\u00e9ket.",
"crm.label.subform.goto.top": "Ugr\u00e1s a lista elej\u00e9re",
"crm.label.subform.row.show.all": "\u00d6sszes megjelen\u00edt\u00e9se ({0})",
"cpq.pr.nodiscount": "Nincs t\u00e1rs\u00edtva kedvezm\u00e9ny",
"crm.inventory.lineitem.no.pricebook.new": "Nincs t\u00e1rs\u00edtott {0}.",
"crm.label.subform.allowedlimit": "El\u00e9rte a megengedett {0} soros korl\u00e1tot",
"crm.reports.integ.okay": "Rendben",
"crm.crud.subform.deleted.record": "A friss\u00edtett mell\u00e9k\u0171rlap-sor(oka)t m\u00e1r t\u00f6r\u00f6lt\u00e9k. Friss\u00edtse az oldalt, majd pr\u00f3b\u00e1lja meg \u00fajra.",
"crux.criteria.fieldlabel.valid.check":"Adjon meg \u00e9rv\u00e9nyes mez\u0151c\u00edmk\u00e9t",
"crm.subform.delete.confirm.header":"Mellékűrlap törlése most",
"crm.subform.delete.confirm.msg": "Biztosan törli a(z) {0} kijelölt sorát?",
"crm.subform.delete.tooltip.msg" :"A sort nem lehet törölni. A(z) {0} legalább egysoros bevitelt igényel.",
	"crm.condition.belongs.to":"a k\u00f6vetkez\u0151h\u00f6z tartozik: {0}",//no i18n
"crm.condition.not.belongs.to":"nem tartozik a k\u00f6vetkez\u0151h\u00f6z: {0}",//no i18n
"crm.orchestration.error.selectvalue": "V\u00e1lasszon ki egy \u00e9rt\u00e9ket",
	"crm.attach.upload.image":"K\u00e9pek csatol\u00e1sa",//no i18n
"crm.general.select.photo":"K\u00e9p kiv\u00e1laszt\u00e1sa",//no i18n
"crm.imageupload.wait.msg":"V\u00e1rjon, am\u00edg a kiv\u00e1lasztott k\u00e9pek felt\u00f6lt\u00e9sre nem ker\u00fclnek.",//no i18n
"crm.image.filepicker.desc.draganddrop":"H\u00fazza ide a k\u00e9peket.",//no i18n
"crm.image.filepicker.desc.browse":"Kattintson a K\u00e9pek b\u00f6ng\u00e9sz\u00e9s\u00e9re ...",//no i18n
"crm.attach.upload.userinfo":"A teljes m\u00e9ret legfeljebb <span class",//no i18n
"crm.image.supported.formats":"T\u00e1mogatott form\u00e1tumok: JPEG, PNG, GIF \u00e9s BMP.",//no i18n
"crm.imageupload.allowed.field.length":"Legfeljebb {0} k\u00e9pet t\u00f6lthet csak fel.",//no i18n
"crm.general.crop.and.set":"K\u00f6rbev\u00e1g\u00e1s \u00e9s be\u00e1ll\u00edt\u00e1s",//no i18n
"crm.attach.option.label":"Csatol\u00e1s",//no i18n
"crm.image.crop.and.rotate":"K\u00f6rbev\u00e1g\u00e1s \u00e9s elforgat\u00e1s",//no i18n
"crm.image.resolution":"Felbont\u00e1s",//no i18n
"crm.attachment.size":"M\u00e9ret",//no i18n
"crm.label.close":"Bez\u00e1r\u00e1s",//no i18n
"crm.label.previous":"El\u0151z\u0151",//no i18n
"crm.label.next":"K\u00f6vetkez\u0151",//no i18n
"crm.subform.record.create.maxrow.limit": "Legfeljebb {0} bejegyz\u00e9s megengedett a k\u00f6vetkez\u0151ben: {1}",
"crm.label.subform.addrows": "Sor hozz\u00e1ad\u00e1sa",
"crm.tax.association.check": "Ehhez a term\u00e9khez nincs ad\u00f3 t\u00e1rs\u00edtva.",
	"crm.gallery.inventory.template.discount": "Kedvezm\u00e9ny",
	"crm.inventory.discount.scheme.range": "Tartom\u00e1ny",
	"Description": "Le\u00edr\u00e1s",
	"crm.inventory.lineitem.no.pricebook": "Nincsenek t\u00e1rs\u00edtott \u00e1rlist\u00e1k.",
	"crm.recordImage.previewimage":"K\u00e9p el\u0151n\u00e9zete",//no i18n
"crm.FileuploadField.addNewImage":"K\u00e9p felt\u00f6lt\u00e9se",//no i18n
"crm.fileuploader.removefile":"Elt\u00e1vol\u00edt\u00e1s",//no i18n
	"voc.gc.configure":"Be\u00e1ll\u00edt\u00e1s",//no i18n
"Edit":"Szerkeszt\u00e9s",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "Adja meg a(z) {0} elemhez illeszked\u0151 \u00e9rt\u00e9ket",
	"crm.label.field":"Mez\u0151",//no i18n
"crm.label.value":"\u00c9rt\u00e9k",//no i18n
	"crm.button.clone":"M\u00e1solat k\u00e9sz\u00edt\u00e9se",//no i18n
	"sentiment.criteria.wrongcriteria":"A krit\u00e9rium \u00e9rt\u00e9ke nem lehet t\u00f6bb enn\u00e9l: {0}",//no i18n
"crm.mb.field.common.splc":"Speci\u00e1lis karakterek haszn\u00e1lata nem megengedett. Adjon meg egy \u00e9rv\u00e9nyes \u00e9rt\u00e9ket.",//no i18n
	"crm.view.attachment.download":"Let\u00f6lt\u00e9s",//no i18n
"crm.label.view":"Megtekint\u00e9s",//no i18n
	"crm.label.field.plural":"mez\u0151k",//no i18n
	"crm.label.in.minutes":"{0} (percben)",//no i18n
	"crm.security.roles.list":"Szerepk\u00f6r\u00f6k list\u00e1ja",//no i18n
"crm.security.roles.lookup.info":"K\u00e9rj\u00fck, jel\u00f6lj\u00f6n ki egy szerepk\u00f6rt a list\u00e1b\u00f3l.",//no i18n
"crm.territory.addterritory":"Ter\u00fclet hozz\u00e1ad\u00e1sa",//no i18n
"crm.title.edit.territory":"Ter\u00fclet szerkeszt\u00e9se",//no i18n
"crm.territory.title.assign.territories":"Ter\u00fcletek hozz\u00e1rendel\u00e9se",//no i18n
	"crm.label.context.help":"Seg\u00edts\u00e9g",//no i18n
	"crm.label.from":"Ett\u0151l:",//no i18n
"crm.label.to":"Eddig:",//no i18n
	"workflow.option.webhookFailure.fromDate":"Kezd\u0151 d\u00e1tum",//no i18n
"workflow.option.webhookFailure.toDate":"Z\u00e1r\u00f3 d\u00e1tum",//no i18n
"crm.custom.field.less.than.equalto":"A(z) {0} nem lehet nagyobb, mint {1}.",//no i18n
	"crm.template.listview.search.no.results":"Nincs tal\u00e1lat",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"A Kezd\u0151d\u00e1tum nem lehet k\u00e9s\u0151bbi, mint a Befejez\u00e9s d\u00e1tuma.",//no i18n
	"crm.label.tag.new":"\u00daj c\u00edmke",//No I18n
	"crm.label.enter.tag":"C\u00edmk\u00e9k bevitele",//No I18n
	"crux.comboBox.max.limit":"Legfeljebb {0} {1} v\u00e1laszthat\u00f3.",//NO I18n
	"Administrator":"Adminisztr\u00e1tor",//No I18n
	"Standard":"Norm\u00e1l",//No I18n
	"crm.button.add":"Hozz\u00e1ad\u00e1s",//NO I18n
	"crm.label.users":"Felhaszn\u00e1l\u00f3k", //NO I18n
  "crm.workflow.alert.roles":"Szerepk\u00f6r\u00f6k", //NO I18n
  "crm.security.groups":"Csoportok", //NO I18n
	"crm.label.available" : "El\u00e9rhet\u0151", //NO I18n
	"crm.label.assign.manually" : "Hozz\u00e1rendel\u00e9s", //NO I18n
	"crm.globalsearch.option.all": "Mind", //NO I18n
	"webform.status.Active":"Akt\u00edv", //NO I18n
	"Inactive":"Inakt\u00edv", //NO I18n
  "Confirmed":"\u00f3v\u00e1hagyva", //NO I18n
  "crm.user.component.unconfirmed":"Nincs j\u00f3v\u00e1hagyva",//no i18n
  "DeletedUser":"T\u00f6r\u00f6lt", //NO I18n
  "crm.feed.group.admin":"Adminisztr\u00e1tor", //NO I18n
  "crm.ln.lable.current":"Jelenlegi", //NO I18n
	"crm.label.selected": "Kiv\u00e1lasztott",//NO I18n
	"crm.auditlog.user": "Felhaszn\u00e1l\u00f3", //NO I18n
	"cob.role": "Szerepk\u00f6r", //NO I18n
	"zoho.email": "E-mail", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Nem tal\u00e1lhat\u00f3k felhaszn\u00e1l\u00f3k.", //NO I18n
	"crm.label.picklist.none": "Nincs", //NO I18n
	"crm.usrpop.non.selected" : "Kijel\u00f6lt felhaszn\u00e1l\u00f3k",//NO I18n
	"crm.zti.label.user": "Felhaszn\u00e1l\u00f3n\u00e9v", //NO I18n
	"crm.label.notSelected" : "Nincs kiv\u00e1lasztva",//NO I18n
	"AM" : "DE",//NO I18n
	"Call" : "H\u00edv\u00e1s",//NO I18n
	"crm.phoneNo.Link.Title" : "H\u00edv\u00e1s a Skype haszn\u00e1lat\u00e1val",//NO I18n
	"crm.button.cancel" : "M\u00e9gse",//NO I18n
	"crm.button.save" : "Ment\u00e9s",//NO I18n
	"crm.no.data.found" : "Nem tal\u00e1lhat\u00f3 adat.",//NO I18n
	"crm.label.no.options.found" : "Nem tal\u00e1lhat\u00f3k opci\u00f3k.",//No I18n
	"crm.globalsearch.search.title" : "Keres\u00e9s",//No I18n
	"None" : "Nincs",//No I18n
	"crm.label.criteria.pattern" : "Krit\u00e9riumminta",//No I18n
	"crm.label.edit.criteria.pattern" : "Minta szerkeszt\u00e9se",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "A minta z\u00e1r\u00f3jeleinek sz\u00e1ma nem egyezik.",//No I18n
	"criteria.error.alert.brackets.invalid" : "\u00c9rv\u00e9nytelen z\u00e1r\u00f3jelek a felt\u00e9teloper\u00e1tor(ok) k\u00f6r\u00fcl.",//No I18n
	"crm.criteria.number.notmatch.check" : "Ellen\u0151rizze a mint\u00e1t itt: {0}.",//No I18n
	"criteria.error.alert.other.params" : "\u00c9rv\u00e9nytelen tartalom a mint\u00e1ban.", //No I18n
	"crm.label.search.for.users": "Felhaszn\u00e1l\u00f3k keres\u00e9se", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "A felt\u00e9telminta nem felel meg a v\u00e1lasztott felt\u00e9teleknek.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "A felt\u00e9telminta nem felel meg a v\u00e1lasztott felt\u00e9teleknek.", //No I18n
	"and" : "\u00e9s", //No I18n
	"or" : "vagy", //No I18n
	"crm.label.or" : "VAGY", //No I18n
	"crm.label.and" : "\u00c9S", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Adjon meg egy \u00e9rv\u00e9nyes mez\u0151c\u00edmk\u00e9t a(z) {0} sorban.", //No I18n
	"crm.criteria.condition.valid.check" : "Adjon meg egy \u00e9rv\u00e9nyes felt\u00e9telt a(z) {0} sz\u00e1m\u00e1ra.", //No I18n
	"crm.field.valid.check" : "K\u00e9rj\u00fck adjon meg egy \u00e9rv\u00e9nyes {0} \u00e9rt\u00e9ket.", //No I18n
	"crm.custom.field.less.than.to" : "A tartom\u00e1ny <i>kezdete</i> nem lehet nagyobb a tartom\u00e1ny <i>v\u00e9g\u00e9n\u00e9l</i>.", //No I18n
	"crm.alert.label.savepattern" : "A felt\u00e9telek m\u00f3dos\u00edt\u00e1sa el\u0151tt mentse a mint\u00e1t.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Tov\u00e1bbi felt\u00e9telek hozz\u00e1ad\u00e1sa nem lehets\u00e9ges.",//No I18n
	"is" : "egyenl\u0151",//No I18n
	"isn\'t" : "nem egyezik",//No I18n
	"contains" : "tartalmaz",//No I18n
	"doesn\'t contain" : "nem tartalmaz",//No I18n
	"starts with" : "kezd\u0151dik",//No I18n
	"ends with" : "v\u00e9gz\u0151dik",//No I18n
	"is empty" : "\u00fcres",//No I18n
	"is not empty" : "nem \u00fcres",//No I18n
	"is before" : "el\u0151tte",//No I18n
	"is after" : "ut\u00e1na",//No I18n
	"between" : "k\u00f6z\u00f6tt",//No I18n
	"not between" : "nincs k\u00f6z\u00f6tte",//No I18n
	"Today" : "Ma",//No I18n
	"Tommorow" : "Holnap",//No I18n
	"Tommorow Onwards" : "Holnapt\u00f3l kezd\u0151dik",//No I18n
	"Yesterday" : "Tegnap",//No I18n
	"Till Yesterday" : "Tegnapig",//No I18n
	"Last Month" : "M\u00falt h\u00f3nap",//No I18n
	"Current Month" : "Aktu\u00e1lis h\u00f3nap", //No I18n
	"Next Month" : "K\u00f6vetkez\u0151 h\u00f3nap", //No I18n
	"Last Week" : "M\u00falt h\u00e9t", //No I18n
	"Current Week" : "Aktu\u00e1lis h\u00e9t", //No I18n
	"Next Week" : "K\u00f6vetkez\u0151 h\u00e9t", //No I18n
	"Age in Days" : "Kor napokban", //No I18n
	"Due in Days" : "Napokon bel\u00fcl esed\u00e9kes", //No I18n
	"Scheduled" : "\u00dctemezett", //No I18n
	"Attended Dialled" : "Fogadott, t\u00e1rcs\u00e1zott", //No I18n
	"Unattended Dialled" : "Nem fogadott, t\u00e1rcs\u00e1zott", //No I18n
	"Overdue" : "K\u00e9s\u00e9s", //No I18n
	"Cancelled" : "Megszak\u00edtva", //No I18n
	"Received" : "Be\u00e9rkezett", //No I18n
	"Missed" : "Elszalasztott", //No I18n
	"crm.alert.character.not.allowed" : "{0} nem enged\u00e9lyezett.", //No I18n
	"crm.condition.in.last" : "az elm\u00falt", //No I18n
	"crm.zinvoice.dueIn" : "esed\u00e9kess\u00e9g:", //No I18n
	"on" : "Ekkor:",//No I18n
	"before" : "el\u0151tt",//No I18n
	"crm.label.general.small.after" : "z ut\u00e1n:",//No I18n
	"crm.thisweek" : "Ez a h\u00e9t",//No I18n
	"crm.label.this.month" : "Ebben a h\u00f3napban",//No I18n
	"crm.thisyear" : "Id\u00e9n",//No I18n
	"crm.source.user.and.system" : "Felhaszn\u00e1l\u00f3 \u00e9s rendszer",//No I18n
	"crm.source.user.or.system" : "Felhaszn\u00e1l\u00f3 vagy rendszer",//No I18n
	"crm.label.system2" : "Rendszer",//No I18n
	"crm.source.user.only" : "Kiz\u00e1r\u00f3lag a felhaszn\u00e1l\u00f3 \u00e1ltal",//No I18n
	"crm.source.system.only" : "Kiz\u00e1r\u00f3lag a rendszer \u00e1ltal",//No I18n
	"crm.condition.till.today" : "A mai napig",//No I18n
	"game.month.left" : "1 h\u00f3nap",//No I18n
	"game.months.left" : "{0} h\u00f3nap",//No I18n
	"crm.condition.last.30.days" : "az elm\u00falt 30 napban",//No I18n
	"crm.condition.last.60.days" : "az elm\u00falt 60 napban",//No I18n
	"crm.condition.last.90.days" : "az elm\u00falt 90 napban",//No I18n
	"crm.label.filter.typehere" : "\u00cdrja be ide", //No I18N
	"crm.filter.is.not" : "nem", //No I18n
	"crm.condition.until.now" : "Mostan\u00e1ig",//No I18n
	"crm.filter.email.isblocked" : "le van tiltva",//No I18n
	"crm.filter.email.isnotblocked" : "nincs letiltva",//No I18n
	"crm.label.no.results.match" : "Nincs tal\u00e1lat",//No I18n
	"crm.label.select.user" : "A felhaszn\u00e1l\u00f3k kiv\u00e1laszt\u00e1s\u00e1hoz kattintson ide.", //No I18n
	"current.logged.in.user": "Bejelentkezett felhaszn\u00e1l\u00f3", //NO I18n
	"current.logged.in.user.definition": "A bejegyz\u00e9sm\u0171veletet kezdem\u00e9nyez\u0151 felhaszn\u00e1l\u00f3.", //NO i18n
	"crm.security.group": "Csoport", //NO I18n
	"crm.security.role": "Szerepk\u00f6r", //NO I18n
	"Date" : "D\u00e1tum",//No I18n
	"crm.field.valid.decimal.check2" : "A(z) <i>{0}</i> mez\u0151 tizedesjegyeinek sz\u00e1ma nem lehet nagyobb, mint {1}.",//No I18n
	"crm.field.empty.check" : "A(z) {0} nem lehet \u00fcres.",//No I18n
	"crm.label.add.note": "Megjegyz\u00e9s hozz\u00e1ad\u00e1sa", //NO I18n
	"crm.label.simply.by": "alapj\u00e1n", //NO I18n
	"crm.general.addnote": "Jegyzet hozz\u00e1ad\u00e1sa", //NO I18n
	"crm.general.addtitle": "C\u00edm hozz\u00e1ad\u00e1sa", //NO I18n
	"crm.label.attach.file": "F\u00e1jl csatol\u00e1sa", //NO I18N
	"crm.button.mass.delete": "T\u00f6rl\u00e9s", //NO I18N
	"crm.warning.delete.record": "Biztosan \u00e1thelyezi a(z) \u201e{0}\u201d elemet a Lomt\u00e1rba?", //NO I18N
	"crm.label.yes": "Igen", //NO I18N
	"crm.note.view.previous": "Kor\u00e1bbi jegyzetek megtekint\u00e9se", //NO I18N
  "of": "a", //NO I18N
	"crm.label.notes": "Megjegyz\u00e9sek", //NO I18N
	"crm.note.recent.first": "Legut\u00f3bbiak k\u00f6z\u00fcl az els\u0151", //NO I18N
	"crm.note.recent.last": "Legut\u00f3bbiak k\u00f6z\u00fcl az utols\u00f3", //NO I18N
	"crm.territory.label.only": "csak {0}", //no i18n
	"crm.select" : "Kiv\u00e1laszt\u00e1s",//No I18n
	"crm.button.apply.filter" : "Sz\u0171r\u0151 alkalmaz\u00e1sa",//No I18n
	"crm.alert.maximum.text.values.contains" : "Ehhez a mez\u0151h\u00f6z nem adhat meg t\u00f6bb, mint {0} \u00e9rt\u00e9ket.",//No I18n
	"PM" : "DU",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Az ism\u00e9tl\u0151d\u0151 elemek nem megengedettek.",//no i18n
	"crm.duplicate.value.available": "Egy azonos {1} \u00e9rt\u00e9k\u0171 {0} tal\u00e1lhat\u00f3.",//no i18n
	"crm.duplicate.value.available.multiple": "Egyn\u00e9l t\u00f6bb {1} \u00e9rt\u00e9k\u0171 {0} tal\u00e1lhat\u00f3.",//no i18n
	"crm.custombutton.nobuttons.found": "Nem tal\u00e1lhat\u00f3 gomb",//no i18n
	"crm.custombutton.create.newbutton": "Gomb l\u00e9trehoz\u00e1sa",//no i18n
	"crm.custombutton.manage.button": "Gombok kezel\u00e9se",//no i18n
	"crm.custombutton.name": "N\u00e9v",//no i18n
	"crm.customize.custombutton.function.desc": "Le\u00edr\u00e1s",//no i18n
	"crm.custombutton.action": "Gombhoz tartoz\u00f3 m\u0171velet",//no i18n
	"crm.custombutton.empty.field.values": "A k\u00f6vetkez\u0151 mez\u0151(k)ben \u00fcres \u00e9rt\u00e9k tal\u00e1lhat\u00f3:",//no i18n
	"crm.custombutton.proceed.action": "Biztosan folytatja?",//no i18n
	"zb.common.Cancel": "M\u00e9gse",//no i18n
	"crm.yes.proceed": "Igen, folytassa.",//no i18n
	"crm.label.module.merge": "{0} egyes\u00edt\u00e9se",//no i18n
	"crm.view.record": "{0} megtekint\u00e9se",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "F\u00e1jl kiv\u00e1laszt\u00e1sa",//no i18n
	"crm.label.subform.addrows": "Sor hozz\u00e1ad\u00e1sa",//no i18n
	"crm.button.save&new": "Ment\u00e9s \u00e9s \u00faj",//no i18n
	//end-cx create form keys
	"crm.mb.newversion.msg4" : "OK, meg\u00e9rtettem!",//No I18n
	"Jan" : "Jan.",//No I18n
	"Feb" : "Febr.",//No I18n
	"Mar" : "M\u00e1rc.",//No I18n
	"Apr" : "\u00c1pr.",//No I18n
	"Jun" : "J\u00fan.",//No I18n
	"Jul" : "J\u00fal.",//No I18n
	"Aug" : "Aug.",//No I18n
	"Sep" : "Szept.",//No I18n
	"Oct" : "Okt.",//No I18n
	"Nov" : "Nov.",//No I18n
	"Dec" : "Dec.",//No I18n
	"crm.label.More" :"T\u00f6bb", //no i18n

	"crm.label.unmapped.stages":"Hi\u00e1nyz\u00f3", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "T\u00f6bb megjelen\u00edt\u00e9se",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Kevesebb megjelen\u00edt\u00e9se",//No I18n

	 	 //filter related keys-start
	"crm.lead.prediction.convert.high":"Magas",//no i18n
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.convert.medium":"K\u00f6zepes",//no i18n
"crm.lead.prediction.convert.low":"Alacsony",//no i18n
"crm.lead.prediction.tooltip.convert":"VAL\u00d3SZ\u00cdN\u0170S\u00c9G",//no i18n
"crm.lead.prediction.tooltip.score":"PONTSZ\u00c1M",//no i18n
"Planned":"Tervezett",//no i18n
"Invited":"Megh\u00edvott",//no i18n
"Sent":"Elk\u00fcldve",//no i18n
"Received":"Be\u00e9rkezett",//no i18n
"Opened":"Megnyitott",//no i18n
"Responded":"Megv\u00e1laszolt",//no i18n
"Bounced":"Visszapattant",//no i18n
"Opted\ Out":"Letiltott",//no i18n
"crm.filter.label.with.open":"Megnyitott {0} elemmel",//no i18n
"crm.filter.label.without.open":"Nyitott {0} n\u00e9lk\u00fcl",//no i18n
"crm.filter.label.without.any":"{0} n\u00e9lk\u00fcl",//no i18n
"crm.filter.label.with.module":"Ezzel: {0}",//no i18n
"crm.filter.label.activity.due":"{0} esed\u00e9kes",//no i18n
"crm.filter.label.activity.done":"{0} k\u00e9sz",//no i18n
"Notes":"Megjegyz\u00e9sek",//no i18n
"crm.filter.label.notes.added":"Jegyzetek hozz\u00e1adva",//no i18n
"crm.label.filter.email.status":"A legfrissebb e-mail \u00e1llapota",//no i18n
"crm.label.filter.email.clicked":"amelyekre r\u00e1kattintottak",//no i18n
"crm.label.filter.email.responded":"megv\u00e1laszolt", //no i18n
"crm.label.filter.email.info":"Bejegyz\u00e9sek sz\u0171r\u00e9se az \u00d6n k\u00fcld\u00f6tt/fogadott e-mail \u00fczeneteinek legutols\u00f3 \u00e1llapota alapj\u00e1n.",//no i18n
"crm.filter.label.sent":"elk\u00fcldve",//no i18n
"crm.filter.label.not.sent":"nincs elk\u00fcldve",//no i18n
"crm.filter.label.opened":"megnyitva",//no i18n
"crm.filter.label.not.opened":"nincs megnyitva",//no i18n
"crm.filter.label.received":"be\u00e9rkezett",//no i18n
"crm.filter.label.not.received":"nem \u00e9rkezett be",//no i18n
"crm.filter.label.bounced":"visszapattant",//no i18n
"crm.filter.label.opened.not.replied":"megnyitott\u00e1k, de nem v\u00e1laszoltak r\u00e1", //no i18n
"crm.filter.label.any":"A fentiek b\u00e1rmelyike",//no i18n
"crm.zia.config.potential.amount":"{0} \u00f6sszege",//no i18n
"Quote\ Stage":"{0} f\u00e1zis",//no i18n
"crm.module.owner":"{0} tulajdonosa",//no i18n
"Potential\ Closing\ Date":"{0} z\u00e1r\u00f3 d\u00e1tuma",//no i18n
"crm.lead.prediction.likely.convert":"Val\u00f3sz\u00edn\u0171 konverzi\u00f3",//no i18n
"crm.predictions.feature.label":"El\u0151rejelz\u00e9s",//no i18n
"crm.intelligence.prediction.likelywin":"V\u00e1rhat\u00f3 gy\u0151zelem",//no i18n
"crm.intelligence.prediction.likelylose":"V\u00e1rhat\u00f3an elveszik",//no i18n
"crm.intelligence.prediction.halfchance":"50:50%-os es\u00e9ly",//no i18n
"crm.intelligence.prediction.score":"El\u0151rebecs\u00fclt pontsz\u00e1m",//no i18n
"crm.lead.prediction.recent.score":"Legutols\u00f3 becsl\u00e9s szerinti pontsz\u00e1m",//no i18n
"crm.intelligence.prediction.lastconv":"Utols\u00f3 3 besz\u00e9lget\u00e9s",//no i18n
"crm.intelligence.prediction.recordsfocus":"Figyelemre \u00e9rdemes bejegyz\u00e9sek",//no i18n
"crm.intelligence.prediction.slowmoving":"Lassan halad\u00f3",//no i18n
"crm.intelligence.prediction.trend.down":"\u00dajabban lefel\u00e9 halad",//no i18n
"crm.label.touched.records":"\u00c9rintett bejegyz\u00e9sek",//no i18n
"crm.label.untouched.records":"\u00c9rintetlen bejegyz\u00e9sek",//no i18n
"crm.label.record.action":"Bejegyz\u00e9sm\u0171velet",//no i18n
"workflow.rule.view.label.Modified":"M\u00f3dos\u00edtva",//no i18n
"crm.label.not.modified":"Nem m\u00f3dos\u00edtott",//no i18n
"crm.label.related.records.action":"Kapcsol\u00f3d\u00f3 bejegyz\u00e9sm\u0171velet",//no i18n
"Done":"K\u00e9sz",//no i18n
"crm.label.not.done":"M\u00e9g nem k\u00e9sz",//no i18n
"sentiment.model":"Az e-mail \u00fczenet hangulata",//no i18n
"sentiment.criteria.count":"Darabsz\u00e1m",//no i18n
"sentiment.criteria.percentage":"Sz\u00e1zal\u00e9k",//no i18n
"sentiment.criteria.lastmail":"A legut\u00f3bbi e-mailre",//no i18n
"Chats":"Besz\u00e9lget\u00e9sek",//no i18n
"Attended":"Kapcsolatba l\u00e9ptek vele",//no i18n
"crm.lead.prediction.popup.text":"Ha a val\u00f3sz\u00edn\u0171 konverzi\u00f3 {0}, akkor az el\u0151rebecs\u00fclt pontsz\u00e1m a k\u00f6vetkez\u0151k k\u00f6z\u00f6tt legyen: {1}.",//no i18n
"crm.lead.prediction.popup.final":"M\u00f3dos\u00edtsa megfelel\u0151en a sz\u0171r\u0151t, majd pr\u00f3b\u00e1lja meg \u00fajb\u00f3l.",//no i18n
"crm.custom.field.less.than.to1":"A tartom\u00e1ny kezdete nem lehet nagyobb a tartom\u00e1ny v\u00e9g\u00e9n\u00e9l.",//no i18n
"Last\ Activity\ Date":"Utols\u00f3 aktivit\u00e1s d\u00e1tuma",//no i18n
"crm.label.vendor.name":"{0} neve",//no i18n
"hours":"\u00f3ra",//no i18n
"days":"nap",//no i18n
"weeks":"h\u00e9t",//no i18n
"months":"h\u00f3napban",//no i18n
"years":"\u00e9v",//no i18n
"crm.label.general.small.after":"ez ut\u00e1n:",//no i18n
"Last\ Week":"M\u00falt h\u00e9t",//no i18n
"Last\ Month":"M\u00falt h\u00f3nap",//no i18n
"crm.module.name":"{0} neve",//no i18n
"Campaign":"Kamp\u00e1ny",//no i18n
"Tasks":"Feladatok",//no i18n
"Calls":"H\u00edv\u00e1sok",//no i18n
"Events":"Esem\u00e9nyek",//no i18n
"sentiment.criteria.wrongcriteria":"A krit\u00e9rium \u00e9rt\u00e9ke nem lehet t\u00f6bb enn\u00e9l: {0}",//no i18n
"crm.chosen.minimum.input.text":"K\u00e9rj\u00fck, legal\u00e1bb {0} karaktert \u00edrjon be",//no i18n
"crm.intelligence.prediction.trendup":"N\u00f6vekv\u0151 tendencia",//no i18n
"crm.intelligence.prediction.trenddown":"Cs\u00f6kken\u0151 tendencia",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Befejezve" ,//no i18n
"crm.label.success":"Sikeres" ,//no i18n
"crm.label.Failure":"Hiba" ,//no i18n
"Both":"Mindkett\u0151" ,//no i18n
"crm.condition.cannot.empty":"A felt\u00e9tel nem lehet \u00fcres.",//no i18n
"crm.condition.last.30.days":"az elm\u00falt 30 napban",//no i18n
"crm.condition.last.60.days":"az elm\u00falt 60 napban",//no i18n
"crm.condition.last.90.days":"az elm\u00falt 90 napban",//no i18n
"crm.sentiment.Positive":"Pozit\u00edv",//no i18n
"crm.sentiment.Negative":"Negat\u00edv",//no i18n
"sentiment.positiveandnegative":"Pozit\u00edv \u00e9s negat\u00edv",//no i18n
"sentiment.positiveornegative":"Pozit\u00edv vagy negat\u00edv",//no i18n
"sentiment.positiveonly":"Csak pozit\u00edv",//no i18n
"sentiment.negativeonly":"Csak negat\u00edv",//no i18n
"crm.sentiment.Neutral":"Semleges",//no i18n
"crm.filters.select.campaign.type":"V\u00e1lassza ki a(z) {0} t\u00edpus\u00e1t",//no i18n
"crm.filters.select.campaign.status":"V\u00e1lassza ki a(z) {0} \u00e1llapot\u00e1t",//no i18n
"campaign.Member" : "Tag",//no i18n
	"Service":"Szolg\u00e1ltat\u00e1s",//no i18n
"Activities":"Tev\u00e9kenys\u00e9gek",//no i18n
"crm.livedesk.pot.nextdays":"A k\u00f6vetkez\u0151 {0} napban",//no i18n
"Today\ +\ Overdue":"Mai nap + esed\u00e9kes",//no i18n
"crm.source.user.and.system":"Felhaszn\u00e1l\u00f3 \u00e9s rendszer",//no i18n
"crm.source.user.or.system":"Felhaszn\u00e1l\u00f3 vagy rendszer",//no i18n
"User":"Felhaszn\u00e1l\u00f3",//no i18n
"crm.source.user.only":"Kiz\u00e1r\u00f3lag a felhaszn\u00e1l\u00f3 \u00e1ltal",//no i18n
"crm.source.system.only":"Kiz\u00e1r\u00f3lag a rendszer \u00e1ltal",//no i18n
"Scheduled":"\u00dctemezett",//no i18n
"Attended\ Dialled":"Fogadott, t\u00e1rcs\u00e1zott",//no i18n
"Unattended\ Dialled":"Nem fogadott, t\u00e1rcs\u00e1zott",//no i18n
"Cancelled":"Megszak\u00edtva",//no i18n
"crm.filter.email.isblocked":"le van tiltva",//no i18n
"crm.filter.email.isnotblocked":"nincs letiltva",//no i18n
"condition.till.now":"Mostan\u00e1ig",//no i18n
"crm.recurring.no.months":"{0} h\u00f3nap",//no i18n
"crm.lead.prediction.tooltip":"Val\u00f3sz\u00edn\u0171 konverzi\u00f3 \u2013 Pontsz\u00e1mtartom\u00e1ny",//no i18n
"crm.website.activity":"Weboldali tev\u00e9kenys\u00e9g",//no i18n
"crm.label.By":"A k\u00f6vetkez\u0151 \u00e1ltal:",//no i18n
"crm.chosen.searching.text":"Keres\u00e9s...",//no i18n
"crm.label.memberstatus.is":"\u00e9s a tag \u00e1llapota",//no i18n
"crm.events.duration":"Id\u0151tartam",//no i18n
"crm.title.clear.name":"T\u00f6rl\u00e9s",//no i18n
"crm.label.status.is":"\u00e9s az \u00e1llapot:",//no i18n
"zia.last3.help":"Besz\u00e9lget\u00e9snek sz\u00e1m\u00edtanak a h\u00edv\u00e1sok, feladatok, {0}, fogadott e-mailek, jegyzetek, l\u00e1togat\u00e1sok, k\u00f6z\u00f6ss\u00e9gi fel\u00fcleteken \u00edrt hozz\u00e1sz\u00f3l\u00e1sok vagy a Desk rendszerbr\u0151l k\u00fcld\u00f6tt t\u00e1mogat\u00e1si k\u00e9relmek.",//no i18n
"crm.label.tag.related.to":"a k\u00f6vetkez\u0151re vonatkozik:",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "\u00daj {0} fog l\u00e9trej\u00f6nni.",//No I18n
	"crm.krp.no.records.found" : "Nem tal\u00e1lhat\u00f3 {0}",//No I18n
	"crm.module.new" : "\u00daj {0}",//No I18n
	"crm.label.view" : "Megtekint\u00e9s",//No I18n
	"crm.nsocial.customers" : "\u00dcgyfelek",//No I18n
	"crm.nsocial.open.potential" : "{0} megnyit\u00e1sa",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Egyebek",//No i18n
	"crm.field.length.check" : "A(z) {0} \u00e9rt\u00e9ke meghaladja a maxim\u00e1lis hosszt.", //No I18n
	"crm.lower.now":"most",//no i18n
	"crm.time.min.ago":"{0} perccel ezel\u0151tt",//no i18n
	"crm.time.mins.ago":"{0} perccel ezel\u0151tt",//no i18n
	"crm.time.hr.ago":"{0} \u00f3r\u00e1val ezel\u0151tt",//no i18n
	"crm.time.hrs.ago":"{0} \u00f3r\u00e1val ezel\u0151tt", //no i18n
	"AllUsers": "\u00d6sszes felhaszn\u00e1l\u00f3", //no i18n
	"crm.label.search":"Keres\u00e9s",//no i18n
	"crm.api.filterby":"Sz\u0171r\u00e9s a k\u00f6vetkez\u0151k szerint",//no i18n
	"crm.customview.nofields.found":"-- Nincsenek illeszked\u0151 mez\u0151k --",//no i18n
	"crm.setup.system.ziarecommendation":"Aj\u00e1nl\u00e1s",//no i18n
	"crm.filter.label.all.products":"\u00d6sszes {0}",//no i18n
	"crm.filter.label.select.products":"Kijel\u00f6lt {0}",//no i18n
	"crm.reviewprocess.smart.filter":"V\u00e9lem\u00e9nyez\u00e9si folyamat alatt l\u00e9v\u0151 bejegyz\u00e9s \u00e1llapota",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"V\u00e1lasszon egy \u00e9rt\u00e9ket.",//no i18n
	"crm.segmentation.segment.score":"Szegmens pontsz\u00e1ma",//no i18n
	"crm.filter.label.in":"a k\u00f6vetkez\u0151 alapj\u00e1n:",//no i18n
	"crm.filter.label.and.purchase.in":"\u00e9s szeretne v\u00e1s\u00e1rolni itt:",//no i18n
	"crm.filter.label.last.purchased":"\u00e9s nemr\u00e9gen v\u00e1s\u00e1rolt",//no i18n
	"crm.filter.label.a.day":"egy nap",//no i18n
	"crm.filter.label.a.week":"egy h\u00e9t",//no i18n
	"crm.filter.label.a.month":"egy h\u00f3nap",//no i18n
	"crm.cal.custom":"Egyedi",//no i18n
	"crm.mb.field.common.empt":"Az \u00e9rt\u00e9k nem lehet \u00fcres.",//no i18n
	"crm.chosen.error.loading.text":"Hopp\u00e1, a tal\u00e1latok bet\u00f6lt\u00e9se nem siker\u00fclt",//no i18n
	"crm.filter.label.firstbuy":"El\u0151sz\u00f6r",//no i18n
	"crm.filter.label.cwbab":"Eltartott",//no i18n
	"crm.filter.label.fbt":"Csomag",//no i18n
	"crm.filter.label.rebuy":"Ism\u00e9tl\u00e9s",//no i18n
	"crm.filter.label.nextbuy":"Sorozat",//no i18n
	"crm.mxnlookup.select" : "{0} hozz\u00e1rendel\u00e9se",//No I18n
	"crm.lookup.chooserecord":"{0} kiv\u00e1laszt\u00e1sa",//no i18n
	"crm.record.selected":"Kiv\u00e1lasztott {0}",//no i18n
	"crm.module.empty.message" : "Nem tal\u00e1lhat\u00f3k {0}",//No I18n
	"crm.mxnlookup.selected" : "Hozz\u00e1rendelt {0}",//No I18n
	"crm.label.creator.noPermission" : "Enged\u00e9ly megtagadva", //No I18n
	"crm.security.error" : "Ehhez a m\u0171velethez nem rendelkezik elegend\u0151 jogosults\u00e1ggal. Forduljon a rendszergazd\u00e1hoz.", //No I18n
	"crm.segmentation.recency" : "K\u00f6zelm\u00falt", //No I18n
	"crm.segmentation.frequency" : "Gyakoris\u00e1g",//No I18n
	"crm.segmentation.monetary" : "P\u00e9nz\u00fcgyi", //No I18n
	"crm.smartfilter.related.module.msg" : "Legfeljebb h\u00e1rom kapcsol\u00f3d\u00f3 modult v\u00e1laszthat ki.", //No I18n
	"crm.smartfilter.related.module.msg1" : "(Pl.: E-mail, Tev\u00e9kenys\u00e9gek, Jegyzetek)", //No I18n
	"crm.smartfilter.related.module.msg2" : "Az id\u0151tartam nem lehet \u00fcres", //No I18n
	"crm.label.timeZone": "Id\u0151z\u00f3na", //NO I18n
	"crm.label.insufficient.privileges": "Nincs jogosults\u00e1ga a m\u0171velet v\u00e9grehajt\u00e1s\u00e1ra. Forduljon rendszergazd\u00e1j\u00e1hoz.", //NO I18n
	"crm.filter.header.secton.system": "Rendszer \u00e1ltal meghat\u00e1rozott sz\u0171r\u0151k", //NO I18N
	"crm.filter.header.secton.fields": "Sz\u0171r\u00e9s mez\u0151k szerint", //NO I18N
	"crm.createfield.calcinfo.new" : "Ez a mez\u0151 a be\u00edrt kifejez\u00e9s kisz\u00e1m\u00edt\u00e1s\u00e1ra szolg\u00e1l.</br> <b>pl. a 20+20</b> be\u00edr\u00e1sakor automatikusan <b>40</b> jelenik meg",//No i18n
	"crm.lable.read.only" : "Csak olvashat\u00f3 mez\u0151",//No i18n
	"crm.column.sort.asc" : "N\u00f6vekv\u0151",//No I18n
	"crm.column.sort.desc" : "Cs\u00f6kken\u0151",//No i18n
	"crm.column.unsort" : "Rendez\u00e9s megsz\u00fcntet\u00e9se",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Megoszt\u00e1s az \u00fcgyf\u00e9llel", //NO I18N
	"crm.label.edited":"Szerkesztve",//no i18n
	"crm.label.edited.on":"Szerkeszt\u00e9s id\u0151pontja:",//no i18n
	"crm.message.limit.exceed": "A(z) {1} csak {0} karaktert tartalmazhat.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "\u00dcgyf\u00e9llel megosztott", //NO I18N
	"crm.button.ok" : "OK", //NO I18N
	"crm.role.already.selected" : "Ezt a szerepk\u00f6rt m\u00e1r kiv\u00e1lasztott\u00e1k", //no i18n
	"crm.user.deleted": "A FELHASZN\u00c1L\u00d3T T\u00d6R\u00d6LT\u00c9K",  //NO I18N
	"crm.account.closed": "EZT A FI\u00d3KOT LEZ\u00c1RT\u00c1K",  //NO I18N
	"crm.start.chat": "Besz\u00e9lget\u00e9s ind\u00edt\u00e1sa",  //NO I18N
	"crm.start.call": "H\u00edv\u00e1s ind\u00edt\u00e1sa",  //NO I18N
	"crm.recipient.invalid.email" : "\u00c9rv\u00e9nytelen e-mail-c\u00edmeket tal\u00e1ltunk.", //NO I18N
	"crm.recipient.add.recipient" : "Tov\u00e1bbi c\u00edmzett hozz\u00e1ad\u00e1sa", //NO I18N
	"crm.start.video.call": "Vide\u00f3h\u00edv\u00e1s ind\u00edt\u00e1sa",  //NO I18N //ignorei18n_start

	"Score":"Pontsz\u00e1m",
	"Positive Score":"Pozit\u00edv pontsz\u00e1m",
	"Negative Score":"Negat\u00edv pontsz\u00e1m",
	"Touch Point Score":"Kapcsol\u00f3d\u00e1si pontra vonatkoz\u00f3 pontsz\u00e1m",
	"Positive Touch Point Score":"Kapcsol\u00f3d\u00e1si pontra vonatkoz\u00f3 pozit\u00edv pontsz\u00e1m",
	"Negative Touch Point Score":"Kapcsol\u00f3d\u00e1si pontra vonatkoz\u00f3 negat\u00edv pontsz\u00e1m",
	"crm.label.scoring.rules":"Pontoz\u00e1si szab\u00e1lyok",
	"crm.label.type.minutes": "\u00cdrja ide az \u00e9rt\u00e9ket percben", //NO I18N

	"is\ OPEN":"NYITOTT",//no i18n
	"is\ WON":"MEGNYERT",//no i18n
	"is\ LOST":"ELVESZ\u00cdTETT",//no i18n
	"crm.potential.all.open":"Minden nyitott f\u00e1zis",//no i18n
	"crm.potential.all.won":"Minden lez\u00e1rt, elnyert f\u00e1zis",//no i18n
	"crm.potential.all.lost":"Minden lez\u00e1rt, elvesztett f\u00e1zis",//no i18n

	"crm.campaign.member.status" : "Tag st\u00e1tusza",//no i18n
	"crm.dashboard.select.type" : "{0} kiv\u00e1laszt\u00e1sa",//no i18n
	"crm.campaign.service.status":"Szolg\u00e1ltat\u00e1s \u00e1llapota",//no i18n

	"crm.label.addColumn":"Oszlop hozz\u00e1ad\u00e1sa",//no i18n
	"crm.button.clear.filter":"Sz\u0171r\u0151 bez\u00e1r\u00e1sa",//no i18n
	"crm.button.show.filter":"Sz\u0171r\u0151 mutat\u00e1sa",//no i18n
	"crm.las.error.user.maxlimit":"Legfeljebb 20 felhaszn\u00e1l\u00f3t v\u00e1laszthat ki.",//no i18n
	"crm.las.error.picklist.maxlimit":"Legfeljebb 20 opci\u00f3t v\u00e1laszthat ki.",//no i18n


	"crm.fileuploader.message.responseerror": "Sikertelen felt\u00f6lt\u00e9s", //NO I18N
	"crm.storage.create.error":"Nem hozhat\u00f3k l\u00e9tre \u00faj bejegyz\u00e9sek, mivel el\u00e9rte a maximum t\u00e1rhely-korl\u00e1tot.",//no i18n
	"crm.storage.create.error.client":"Nem hozhat\u00f3k l\u00e9tre \u00faj bejegyz\u00e9sek, mert a rendszergazda el\u00e9rte a maximum t\u00e1rhely-korl\u00e1tot. A probl\u00e9ma megold\u00e1s\u00e1hoz forduljon a k\u00f6vetkez\u0151h\u00f6z: {0}.",//no i18n
	"crm.storage.avail.info":"({0} / {1} maradt fenn)",//no i18n
	"crm.storage.error.key.manage":"A t\u00e1rhely kezel\u00e9se",//no i18n
	"Records":"Bejegyz\u00e9sek",//no i18n
	"crm.workflow.alert.additional.recipients" : "Egy\u00e9b c\u00edmzettek", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "A tov\u00e1bbi e-mail c\u00edmeket vessz\u0151vel v\u00e1lassza el egym\u00e1st\u00f3l.", //NO I18N
	"crm.related.contact.account" : "A(z) {0} a k\u00f6vetkez\u0151re vonatkozik: {1}",//No I18n
	"crm.allcontact.show" : "Minden {0}",//No I18n
	"crm.button.mass.show" : "Megjelen\u00edt\u00e9s",//No I18n
	"crm.msg.custom.view.not.replied" : "Megv\u00e1laszolatlan \u00fczenetek", //NO I18N
	"crm.msg.custom.view.replied" : "Megv\u00e1laszolt \u00fczenetek",//NO I18N
	"crm.workflow.select.recipients" : "C\u00edmzettek", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"K\u00e9rj\u00fck, legal\u00e1bb egy profilt v\u00e1lasszon ki.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Sajn\u00e1ljuk, de az alap\u00e9rtelmezett profilt nem lehet t\u00f6r\u00f6lni.",//NO I18N
	"crm.inv.label.add.emails" : "E-mail c\u00edmek hozz\u00e1ad\u00e1sa", //NO I18N
	"crm.prediction.analytics.filter.year":"Tavaly",//no i18n
	"Previous\ FY":"El\u0151z\u0151 gazdas\u00e1gi \u00e9v",//no i18n
	"Current\ FY":"Aktu\u00e1lis gazdas\u00e1gi \u00e9v",//no i18n
	"Next\ FY":"K\u00f6vetkez\u0151 gazdas\u00e1gi \u00e9v",//no i18n
	"Current\ FQ":"Aktu\u00e1lis negyed\u00e9v",//no i18n
	"Next\ FQ":"K\u00f6vetkez\u0151 negyed\u00e9v",//no i18n
	"Previous\ FQ":"El\u0151z\u0151 negyed\u00e9v",//no i18n
	"crm.picklist.sample.text":"Sz\u00f6vegminta",//no i18n
	"crm.more.colors":"Tov\u00e1bbi sz\u00ednek",//no i18n
	"crm.button.back.alone":"Vissza",//no i18n
	"crm.field.label.email":"E-mail c\u00edm",//no i18n
"crm.zia.nba.feature.label":"A k\u00f6vetkez\u0151 legjobb m\u0171velet",//no i18n
"Meeting":"Tal\u00e1lkoz\u00f3",//no i18n
"Tomorrow":"Holnap",//no i18n
"crm.gdpr.notavailable.field":"Nem \u00e9rhet\u0151 el",//no i18n
	"crm.button.back.alone":"Vissza",//no i18n,
	"crm.setup.system.ziasimilarity":"Hasonl\u00f3s\u00e1gi javaslat",//no i18n
	"crm.gdpr.notavailable.field":"Nem \u00e9rhet\u0151 el",//no i18n
	"crm.label.simply.in":"a k\u00f6vetkez\u0151ben:",//no i18n
	"crm.filter.label.all.products":"\u00d6sszes {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Szegmens", // NO I18N
	"abm.segment.names": "Szegmensnevek", // NO I18N
	"abm.abm.segment.name": "ABM-szegmens neve", // NO I18N
	"abm.segmentation.techniques": "ABM-szegment\u00e1l\u00e1si m\u00f3dszerek", // NO I18N
	"abm.filters": "ABM-sz\u0171r\u0151k", // NO I18N
	"abm.filters.info": "Ezek a sz\u0171r\u0151k a(z) {1} elemhez t\u00e1rs\u00edtott {0} alapj\u00e1n jelen\u00edtik meg az adatokat.", // NO I18N
	"abm.rfm.label": "RFM-c\u00edmke", // NO I18N
	"abm.firmographics.label": "Firmogr\u00e1fiai c\u00edmke" ,// NO I18N
	"abm.recommendation.label": "Aj\u00e1nl\u00e1si c\u00edmke", // NO I18N
	"abm.voc.label": "Az \u00fcgyf\u00e9l hangja-c\u00edmke", // NO I18N
	"abm.engagement.label": "Kapcsolattart\u00e1si c\u00edmke", // NO I18N
	"abm.overall.score": "\u00d6sszes\u00edtett pontsz\u00e1m", // NO I18N
	"abm.mood.score": "Hangulati pontsz\u00e1m", // NO I18N
	"abm.value.score": "\u00c9rt\u00e9kpontsz\u00e1m", // NO I18N
	"abm.engagement.score": "Kapcsolattart\u00e1si pontsz\u00e1m", // NO I18N
	"abm.rfm.score": "RFM-pontsz\u00e1m", // NO I18N
	"crm.customview.pin.column":"Pin Column",//no i18n
	"crm.customview.unpin.column":"Unpin Column",//no i18n
	"crm.column.list.max.group.limit" : "", //no i18n
	"crux.custom.field.greater.than.equalto":"A(z) {0} nem lehet kisebb, mint {1}.",
	"crux.users.selected.plural" : "{0} felhasználó kijelölve.",
	"crux.user.selected.singular" :"{0} felhasználó kijelölve.",
	"crux.criteria.empty.secondaryfield.module" : "Nincsenek illeszkedő {0}-mezők a(z) {1} modulban",
	"crux.criteria.empty.secondaryfield" : "Az összehasonlításhoz nem áll rendelkezésre más {0}-mező, adjon meg egy összehasonlítási értéket.",
	"crux.logged.in.role.definition" : "A bejegyzésműveletet kezdeményező felhasználó szerepköre",
	"crux.max.limit.unselect" : "Legfeljebb {0} {1} kijelölése törölhető.", //NO I18N
	"crux.existing.tag" : "A(z) \u201e{0}\u201d már ki van jelölve", //NO I18N
	"crm.label.true" : "Igaz",//NO I18N
	"crm.label.false" : "Hamis",//NO I18N
	"crm.record.locked" : "Z\u00e1rolva",//NO I18N
	"crm.filter.number.range":"{0} \u00e9s {1} k\u00f6z\u00f6tti \u00e9rt\u00e9kek",//no i18n
	"crm.smartfilter.picklist.options.msg":"Ezt az opci\u00f3t legfeljebb 10 kijel\u00f6lt \u00e9rt\u00e9khez v\u00e1laszthatja ki.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"Legfeljebb 5 {0} v\u00e1laszthat\u00f3 ki",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Legfeljebb {0} {1} v\u00e1laszthat\u00f3 ki",//no i18n
	"crm.best.time.column.label":"Legjobb kapcsolatfelv\u00e9teli id\u0151pont",//no i18n
	"crm.competitoralert.mentioned.in.email":"Megeml\u00edtve az <b>e-mailben</b>",//no i18n
	"crm.competitoralert.with.sentiment":"Hangulattal",//no i18n
	"crm.competitoralert.name.error":"\u00c9rv\u00e9nyes \u00e9rt\u00e9ket adjon meg a versenyt\u00e1rs nev\u00e9n\u00e9l.",//no i18n
	"crm.competitoralert.date.error":"\u00c9rv\u00e9nyes d\u00e1tumot adjon meg a versenyt\u00e1rs e-mailben t\u00f6rt\u00e9n\u0151 megeml\u00edt\u00e9s\u00e9n\u00e9l.",//no i18n
	"crm.competitoralert.sentiment.error":"\u00c9rv\u00e9nyes opci\u00f3t v\u00e1lasszon ki a versenyt\u00e1rs hangulat\u00e1n\u00e1l.",//no i18n
	"crm.competitor.name":"Versenyt\u00e1rs",
"crm.lookup.advance.error.msg" : "Hiba t\u00f6rt\u00e9nt a sz\u0171r\u0151 alkalmaz\u00e1sa sor\u00e1n. Friss\u00edtse az oldalt, majd pr\u00f3b\u00e1lkozzon \u00fajra.",
	"crm.label.add.tags":"C\u00edmk\u00e9k hozz\u00e1ad\u00e1sa",//no i18n
	"crm.confirm.deassociate":"Hozz\u00e1rendel\u00e9s megsz\u00fcntet\u00e9se",//no i18n
	"Contact\ Name" : "{0} neve",
	"Lead\ Name" : "{0} neve",
	"-None-" : "Nincs",
	"January" : "Janu\u00e1r",
	"February":"Febru\u00e1r",
	"March":"M\u00e1rcius",
	"April":"\u00c1prilis",
	"May":"M\u00e1jus",
	"June":"J\u00fanius",
	"July":"J\u00falius",
	"August":"Augusztus",
	"September":"Szeptember",
	"October":"Okt\u00f3ber",
	"November":"November",
	"December":"December",
	// Image Upload Open
	"crm.label.browse.files":"F\u00e1jlok tall\u00f3z\u00e1sa",//no i18n
	"crm.label.lowercase.or":"vagy",//no i18n
	"crm.image.header":"K\u00e9p",//no i18n
	"crm.image.n.n":"N:N ar\u00e1ny",//no i18n
	"crm.image.2.2":"2:2 ar\u00e1ny",//no i18n
	"crm.image.4.3":"4:3 ar\u00e1ny",//no i18n
	"crm.image.16.9":"16:9 ar\u00e1ny",//no i18n
	"crm.image.height.width.swap":"Magass\u00e1g \u00e9s sz\u00e9less\u00e9g felcser\u00e9l\u00e9se",//no i18n
	"crm.image.rotate.image":"Elforgat\u00e1s",//no i18n
	"crm.label.lowercase.of":"ebb\u0151l",//no i18n
	"crm.image.empty.name.placeholder" : "Adjon meg egy nevet",
	"crm.image.crop.and.rotate" : "K\u00f6rbev\u00e1g\u00e1s \u00e9s elforgat\u00e1s",
	"crm.image.description" : "Le\u00edr\u00e1s hozz\u00e1ad\u00e1sa...",
	"crm.image.actual.size" : "T\u00e9nyleges m\u00e9ret",
	"crm.image.reset" : "Alap\u00e1llapot",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"Let\u00f6lt\u00e9s",//no i18n
	"crm.label.edit":"Szerkeszt\u00e9s",//no i18n
	"crm.label.close":"Bez\u00e1r\u00e1s",//no i18n
	"crm.label.any":"B\u00e1rmely",
	"crm.image.description":"Le\u00edr\u00e1s hozz\u00e1ad\u00e1sa...",//no i18n
	"crm.image.zoom.in":"Nagy\u00edt\u00e1s",//no i18n
	"crm.image.zoom.out":"Kicsiny\u00edt\u00e9s",//no i18n
	"crm.label.lowercase.of":"ebb\u0151l",//no i18n
	"crm.image.desc.maxsize":"A le\u00edr\u00e1s nem haladhatja meg az 255 karaktert.",//no i18n
	"crm.image.name.maxsize":"A n\u00e9v nem haladhatja meg a 100 karaktert.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"A f\u00e1jl teljes m\u00e9rete meghaladja a(z) {0} megengedett limitet.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"Legfeljebb {0} f\u00e1jlt t\u00f6lthet fel.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"Legfeljebb {0} f\u00e1jl csatolhat\u00f3.",//no i18n
	"crm.ImageuploadField.size.limit":"A teljes k\u00e9pm\u00e9ret meghaladja a megengedett {0}MB-os korl\u00e1tot.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"Csak 1 k\u00e9pet v\u00e1laszthat",//no i18n
	"crm.image.crop.error":"A k\u00e9pet nem lehet lev\u00e1gni, pr\u00f3b\u00e1lkozzon \u00fajra!",//no i18n
	"crm.attach.here":"ide.",//no i18n
	"crm.image.unsupported.corrupted.single":"A felt\u00f6lteni k\u00edv\u00e1nt fot\u00f3 s\u00e9r\u00fclt.",//no i18n
	"Browse":"Tall\u00f3z\u00e1s",//no i18n
	"crm.imageupload.failure":"N\u00e9h\u00e1ny k\u00e9p felt\u00f6lt\u00e9se nem siker\u00fclt. K\u00e9rj\u00fck, pr\u00f3b\u00e1lja meg \u00fajra k\u00e9s\u0151bb.",//no i18n
	"zc.editor.width":"Sz\u00e9less\u00e9g",//no i18n
	"ze.editor.height":"Magass\u00e1g",//no i18n
	"crm.label.delete":"T\u00f6rl\u00e9s",//no i18n
	"crm.image.error.gif":"GIF-form\u00e1tum\u00fa k\u00e9pet nem lehet k\u00f6rbev\u00e1gni.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} egy\u00e9b f\u00e1jl",//no i18n
	"crm.fileuploader.message.invalidfileType1":"A(z) {0} f\u00e1jl nem t\u00e1mogatott.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"A(z) {0} \u00e9s {1} f\u00e1jl nem t\u00e1mogatott.",//no i18n
	"crm.attach.upload.sizelimit":"Teljes m\u00e9retkorl\u00e1t",//no i18n
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "Legfeljebb {0} f\u00e1jlt t\u00f6lthet fel.",
	"crm.file.upload.maxlength.exceeds" : "Csak egy f\u00e1jlt t\u00f6lthet fel a k\u00f6vetkez\u0151ben: {0}."


}
